// @ts-ignore
import buttonStyles from '../../styles/button.css';
// @ts-ignore
import calloutStyles from '../../styles/callout.css';
// @ts-ignore
import cookieBannerStyles from '../../styles/cookie-banner.css';
// @ts-ignore
import creatorBannerStyles from '../../styles/creator-banner.css';
// @ts-ignore
import inputStyles from '../../styles/inputs.css';

import type { LinksFunction } from '@remix-run/cloudflare';

export const fontLinks: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: creatorBannerStyles },
  { rel: 'stylesheet', href: inputStyles },
  { rel: 'stylesheet', href: buttonStyles },
  { rel: 'stylesheet', href: calloutStyles },
  { rel: 'stylesheet', href: cookieBannerStyles },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300..800&family=Inconsolata:wght@300..800&family=Oswald:wght@300..700&family=Playfair+Display:wght@400..800&family=Libre+Franklin:wght@300..800&family=Work+Sans:wght@300..800&family=Space+Grotesk:wght@300..700&family=Sometype+Mono:wght@400..700&family=Sofia+Sans:wght@300..800&family=Outfit:wght@300..800&family=Roboto+Slab:wght@300..800&family=Jura:wght@400..700&family=Noto+Serif:wght@300..800&family=Crimson+Pro:wght@300..800&display=swap'
  }];

};